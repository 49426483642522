<template>
  <v-app id="inspire">
    <v-main class="bg-grey-lighten-3">
      <!-- パンくずリスト -->
      <CustomBreadcrumb :breadcrumbs="breadcrumbs" />

      <!-- メインコンテンツ -->
      <v-container fluid>
        <v-row class="justify-center">
          <!-- 左側のサイドバー -->
          <v-col cols="12" md="3">
            <v-sheet elevation="2" rounded="lg" class="pa-4">
              <!-- サイドバー内容 -->
            </v-sheet>
          </v-col>

          <!-- 右側のコンテンツ -->
          <v-col cols="12" md="9">
            <v-sheet elevation="2" rounded="lg" class="pa-4">
              <h2 class="text-h5 font-weight-bold mb-4">お知らせ一覧</h2>
              <v-list>
                <v-list-item
                  v-for="(notification, index) in notifications"
                  :key="index"
                  class="mb-3"
                >
                  <v-card elevation="3" rounded="lg" class="pa-3 w-100">
                    <v-list-item-content>
                      <a
                        :href="`/notice/${notification.id}`"
                        class="text-decoration-none text-blue-darken-2"
                      >
                        <h2 class="text-h6 font-weight-bold mb-2">
                          {{ notification.title }}
                        </h2>
                      </a>
                      <v-list-item-subtitle class="text-grey-darken-1">
                        作成日時: {{ formatDate(notification.created_at) }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="text-grey-darken-1">
                        更新日時: {{ formatDate(notification.update_at) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-card>
                </v-list-item>
              </v-list>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import CustomBreadcrumb from "@/components/CustomBreadcrumb.vue";
import {
  getAllNotices,
  getNoticesNoArchive,
} from "@/firebase/firebase_function";

export default {
  props: ["admin"],
  components: {
    CustomBreadcrumb,
  },
  setup(props) {
    const admin = ref(props.admin);
    const notifications = ref([]);
    const selectedNotification = ref(null);

    const selectNotification = (notification) => {
      selectedNotification.value = notification;
    };

    const fetchNotifications = async () => {
      try {
        notifications.value = admin.value
          ? await getAllNotices()
          : await getNoticesNoArchive();
      } catch (error) {
        console.error("通知の取得中にエラーが発生しました:", error);
      }
    };

    onMounted(() => {
      fetchNotifications();
    });

    return {
      notifications,
      selectedNotification,
      selectNotification,
    };
  },
  computed: {
    breadcrumbs() {
      const route = useRoute();
      return route.meta.breadcrumbs || [];
    },
  },
  methods: {
    formatDate(timestamp) {
      if (!timestamp) return "不明";

      let date;

      // Firebase Timestamp の場合
      if (timestamp.seconds) {
        date = new Date(timestamp.seconds * 1000);
      } else {
        // Date オブジェクトまたは文字列の場合
        date = new Date(timestamp);
      }

      // YYYY/MM/DD HH:mm 形式に変換
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // 月は0始まりなので +1
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");

      return `${year}/${month}/${day} ${hours}:${minutes}`;
    },
  },
};
</script>

<style>
</style>