<template>
  <div class="comment-section">
    <!-- コメント入力フォーム -->
    <form @submit.prevent="createComment" class="comment-form">
      <input
        v-model="newInitial"
        :placeholder="!user ? 'イニシャルを入力...' : '管理者'"
        :required="!user"
        maxlength="2"
      />
      <textarea
        v-model="newComment"
        placeholder="コメントを入力..."
        required
        maxlength="500"
      ></textarea>
      <div class="comment-caption">
        <p>・コメントの削除は管理者のみが可能です</p>
        <p>・運用上で予告なくコメントを削除する場合があります</p>
        <!-- <p>・XSS対策のために一部の特殊文字はサニタイズされます</p>
        <p>・入力した情報はGoogle Cloud上に保存されます</p> -->
      </div>
      <div class="mt-2" />
      <button type="submit" class="submit-btn">
        <span class="material-icons">send</span>
      </button>
    </form>

    <!-- マークダウンプレビュー -->
    <div v-if="newComment.trim()">
      <div class="mt-2 comment-item">
        <h5>プレビュー</h5>
        <div class="markdown-preview">
          <div class="comment-header">
            <strong v-if="user">管理者</strong>
            <strong v-else>{{ newInitial }}</strong>
          </div>
          <div v-html="convertMarkdown(newComment)"></div>
        </div>
      </div>
    </div>

    <!-- コメントリスト -->
    <div v-if="comments.length > 0" class="comments-list">
      <div v-for="comment in comments" :key="comment.id" class="comment-item">
        <div class="comment-header">
          <strong v-if="comment.is_admin">管理者</strong>
          <strong v-else>{{ comment.sender }}</strong>
        </div>
        <!-- マークダウン変換 -->
        <div class="markdown-preview">
          <div v-html="convertMarkdown(comment.content)"></div>
        </div>
        <div class="comment-footer">
          <p class="timestamp">{{ formatDate(comment.created_at) }}</p>
          <button
            v-if="user"
            @click="deleteComment(comment.id)"
            class="delete-btn"
          >
            <span class="material-icons">cancel</span>
          </button>
        </div>
      </div>
    </div>

    <div v-else-if="comments.length == 0">
      <v-progress-circular color="#f3948f" indeterminate></v-progress-circular>
    </div>

    <div v-else>
      <p>まだコメントはありません。</p>
    </div>
  </div>
</template>

<script>
import { marked } from "marked";
import DOMPurify from "dompurify";
import {
  getAllComments,
  createComment,
  deleteComment,
} from "@/firebase/firebase_function";
import { serverTimestamp } from "firebase/firestore";
import { useCurrentUser } from "vuefire";
import "./markdown.css";
export default {
  data() {
    return {
      newInitial: "", // 新しいイニシャルの入力値
      newComment: "", // 新しいコメントの入力値
      comments: [], // コメントのリスト
      user: null,
    };
  },
  methods: {
    sanitizeInput(input) {
      return DOMPurify.sanitize(input, {
        ALLOWED_TAGS: ["span", "details", "summary"],
      });
    },
    validateInputs(sender, content) {
      // イニシャルの長さ制限（2文字）
      if (!this.user && sender.length > 2) {
        alert("イニシャルは2文字以内で入力してください");
        return false;
      }

      // コメントの長さ制限（500文字）
      if (content.length > 500) {
        alert("コメントは500文字以内で入力してください");
        return false;
      }

      return true;
    },
    // コメントを追加するメソッド
    async createComment() {
      if (this.newComment.trim()) {
        const sender = this.sanitizeInput(this.newInitial.trim() || "名無し");
        const content = this.sanitizeInput(this.newComment.trim());

        // 入力のバリデーション
        if (!this.validateInputs(sender, content)) {
          return;
        }
        await createComment({
          sender,
          content,
          is_admin: this.user ? true : false,
          archive_flag: false,
          created_at: serverTimestamp(),
          update_at: serverTimestamp(),
        });
        this.newInitial = "";
        this.newComment = "";
        this.fetchComments();
      }
    },

    async deleteComment(commentId) {
      try {
        await deleteComment(commentId);
        this.fetchComments();
      } catch (error) {
        console.error("コメントの削除に失敗しました:", error);
      }
    },

    async fetchComments() {
      try {
        const comments = await getAllComments();
        this.comments = comments;
      } catch (error) {
        console.error("コメントの取得に失敗しました:", error);
      }
    },

    formatDate(timestamp) {
      if (!timestamp) return "不明";
      let date;

      if (timestamp.seconds) {
        date = new Date(timestamp.seconds * 1000);
      } else {
        date = new Date(timestamp);
      }

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // 月は0始まりなので +1
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");

      return `${year}/${month}/${day} ${hours}:${minutes}`;
    },

    convertMarkdown(text) {
      return marked(text); // コメントをマークダウンからHTMLに変換
    },
  },
  created() {
    this.fetchComments();
    this.user = useCurrentUser();
  },
};
</script>

<style scoped>
.comment-section {
  margin-top: 30px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  font-family: "Roboto", sans-serif;
}

h3 {
  text-align: center;
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
}

.comment-form {
  background-color: #f4f4f4; /* 明るいグレーに変更 */
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

input {
  width: 100%;
  height: 40px;
  margin-bottom: 15px;
  padding: 12px;
  border-radius: 10px;
  border: 1px solid #ddd;
  font-size: 14px;
  resize: none;
  background-color: #fff;
}

textarea {
  width: 100%;
  height: 80px;
  margin-bottom: 5px;
  padding: 12px;
  border-radius: 10px;
  border: 1px solid #ddd;
  font-size: 14px;
  resize: none;
  background-color: #fff;
}

textarea:focus {
  outline: none;
}

.submit-btn {
  color: white;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
}

.submit-btn .material-icons {
  font-size: 20px;
}

.comments-list {
  margin-top: 30px;
  /* max-height: 400px;
  overflow-y: auto; */
  padding: 10px;
}

.comment-item {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f9f9f9; /* 薄いベージュのような背景 */
  border-radius: 12px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: left; /* 左寄せ */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.comment-item:hover {
  transform: scale(1.02);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.comment-header {
  font-size: 16px;
  font-weight: bold;
  text-align: left; /* 左寄せ */
  margin-bottom: 8px; /* イニシャルとコメントの間に余白を追加 */
}

.comment-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.timestamp {
  font-size: 12px;
  color: #777;
  text-align: left; /* 左寄せ */
}

.timestamp a {
  color: #007bff; /* リンクの色 */
  text-decoration: none; /* 下線を削除 */
}

.timestamp a:hover {
  text-decoration: underline; /* ホバー時に下線を表示 */
}

.delete-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  transition: color 0.3s ease;
}

.delete-btn:hover {
  color: #e53935;
}

.material-icons {
  font-size: 24px;
  color: #f44336;
}

.comment-item a {
  color: blue; /* リンクの色を青に設定 */
  text-decoration: underline; /* 下線を追加 */
}

.comment-item a:hover {
  color: darkblue; /* ホバー時に色を変える（任意） */
}

.markdown-preview {
  margin-top: 20px;
  padding: 10px;
  border-radius: 10px;
}

.markdown-preview h4 {
  margin-bottom: 10px;
  font-size: 16px;
}

.markdown-preview div {
  font-size: 14px;
}

.comment-caption {
  font-size: 0.75em;
  color: #666;
  text-align: left;
  border-left: 3px solid #ccc; /* キャプション風の左線 */
  padding-left: 8px;
  margin-top: 10px;
}
.comment-caption p {
  margin: 3px 0;
}
</style>

