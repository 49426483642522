<template>
  <v-app id="inspire">
    <v-main class="bg-grey-lighten-3">
      <!-- パンくずリスト -->
      <CustomBreadcrumb :breadcrumbs="breadcrumbs" />
      <!-- メインコンテンツ -->
      <v-container fluid>
        <v-row class="center-align">
          <!-- 左側のサイドバー -->
          <v-col cols="12" md="3">
            <v-col>
              <v-sheet
                rounded="lg"
                :style="{
                  maxHeight: '1000px',
                  overflow: 'auto',
                }"
              >
                <div class="my-5" />
                <!-- サイドバー内容 -->
                <div class="centered-content">
                  <!-- プロフィール画像 -->
                  <div class="author_img">
                    <v-avatar size="80">
                      <img
                        decoding="async"
                        alt="プロフィール画像"
                        src="../assets/neko.png"
                        class="avatar avatar-100 photo"
                        loading="lazy"
                      />
                    </v-avatar>
                  </div>

                  <v-row
                    class="mb-1"
                    align="center"
                    style="justify-content: center"
                  >
                    <strong style="color: black; font-size: 1.2em">reo</strong>
                    <span
                      class="material-icons"
                      style="color: #1da1f2; font-size: 1.2em; margin-left: 4px"
                    >
                      verified
                    </span>
                  </v-row>

                  <p style="font-size: 0.9em; color: gray; margin-bottom: 8px">
                    超初級エンジニア
                  </p>

                  <v-row
                    class="mb-2 mt-2"
                    style="
                      font-size: 0.9em;
                      color: gray;
                      justify-content: center;
                    "
                  >
                    <span
                      ><strong style="color: black">100</strong>
                      フォロー中</span
                    >
                    <span style="margin-left: 16px"
                      ><strong style="color: black">101</strong>
                      フォロワー</span
                    >
                  </v-row>
                </div>

                <AuthWidget
                  :user="user"
                  :signinPopup="handleSignin"
                  :logout="handleLogout"
                />
              </v-sheet>
            </v-col>
            <v-col>
              <v-sheet
                rounded="lg"
                :style="{
                  maxHeight: '1000px',
                  overflow: 'auto',
                }"
              >
                <div class="my-5" />
                <MdCatalog
                  class="md-catalog"
                  :editorId="id"
                  :scrollElement="state.scrollElement"
                  :mdHeadingId="mdHeadingId"
                  @onClick="handleCatalogClick"
                />
                <div class="my-5" /> </v-sheet
            ></v-col>
          </v-col>

          <!-- メインコンテンツ -->
          <v-col cols="12" md="8" class="main-content">
            <section class="container-vertical-layout">
              <div class="not-auth">
                <MdPreview
                  class="md-preview"
                  v-model="content"
                  :editorId="id"
                  :mdHeadingId="mdHeadingId"
                  language="jp_JP"
                  preview-theme="github"
                />
              </div>
            </section>
          </v-col>

          <!-- 右側のサイドバー -->
          <v-col cols="12" md="3">
            <AdvertisementWidget />
            <div class="my-5" />
          </v-col> </v-row
        ><FadeTopWidget />
      </v-container>
    </v-main>
  </v-app>
</template>


<script setup>
import { useRoute } from "vue-router";
import CustomBreadcrumb from "@/components/CustomBreadcrumb.vue";
import AdvertisementWidget from "@/components/AdvertisementWidget.vue";
import AuthWidget from "@/components/AuthWidget.vue";
import { MdPreview, MdCatalog } from "md-editor-v3";
import "md-editor-v3/lib/style.css";
import { ref, computed } from "vue";
import { logout, signinPopup } from "@/utils/auth";
import { useCurrentUser } from "vuefire";
import FadeTopWidget from "@/components/FadeTopWidget.vue";

const id = `md-editor-about`; // 一意のエディタID
const mdHeadingId = (_text, _level, index) => `heading-${index}`;
const user = useCurrentUser();
const content = ref(`


## はじめに

**新卒3年目のフロントエンドエンジニア**  
主に **Vue.js** を使用して、ユーザーインターフェースの設計や、システム全体のフロントエンド開発を担当しています。

---

## 技術系

### スタック

| 分類             | 使用技術                      |
|------------------|-----------------------------|
| **プログラミング言語** | Java, Dart, JavaScript        |
| **フレームワーク**     | Vue.js, Flutter        |
| **BaaS**         | Firebase, Supabase           |
| **データベース**     | PostgreSQL                   |

---
## 資格系

### 頑張って取得した資格試験

1. 📜 **基本情報技術者試験**  （2020, 10）
2. 📜 **応用情報技術者試験**  （2023, 4）
3. 🥈 **Oracle Certified Java Programmer, Silver SE 8**（2020, 10, 23）
4. 🥇 **Oracle Certified Java Programmer, Gold SE 11**（2022, 12, 17）

---

### 現在挑戦中の資格試験

- 🔒 **情報処理安全確保支援士（登録セキスぺ）**  
- 📊 **Oracle Master Silver DBA 2019**  

---

## 最後に

📌 **まだまだ経験が浅く知識不足なので、以下を頑張ります！**

- 個人開発：より多くのプロジェクトに挑戦し、実務経験を積むことでスキルを向上させます。
- 資格勉強：さらに深い専門知識を得るため、現在の資格に加えて新たな資格取得を目指します。
- 知識アウトプット：技術ブログとして学んだことをアウトプットしたいですが、めんどくさいのでChatGPT様に書いてもらっています。

## 更新
📅 **2025年1月**

---

`);
// テキストとスクロール要素の状態をリアクティブな変数として定義します
const state = {
  text: ref("# heading"), // デフォルトのエディターテキスト
  scrollElement: document.documentElement, // デフォルトのスクロール要素
};
const breadcrumbs = computed(() => {
  const route = useRoute();
  return route.meta.breadcrumbs || [];
});
const handleSignin = () => {
  signinPopup();
};
const handleLogout = () => {
  logout();
};
</script>

<style>
/* コード内の特定の部分を強調する例 */
.highlight {
  background-color: rgba(255, 255, 0, 0.2); /* 黄色の背景 */
  font-weight: bold; /* 太字に */
}
</style>