<template>
  <v-app id="inspire">
    <v-main class="bg-grey-lighten-4">
      <!-- パンくずリスト -->
      <CustomBreadcrumb :breadcrumbs="breadcrumbs" />
      <v-container fluid>
        <v-row justify="center">
          <v-col class="col-1" :cols="getColCount1">
            <v-sheet
              min-height="70vh"
              rounded="xl"
              class="d-flex flex-column justify-center pa-6 elevation-3 bg-white"
            >
              <div class="projects-list">
                <v-container>
                  <h1 class="text-h4 font-weight-bold mb-4">プロダクト</h1>
                  <v-card elevation="2" rounded="lg">
                    <v-list lines="two">
                      
                      <template v-for="project in projects" :key="project.id">
                        <v-list-item :href="project.link" class="hover-list-item">
                          <template v-slot:prepend>
                            <v-avatar size="48" rounded>
                              <v-img :src="project.image" class="rounded" cover></v-img>
                            </v-avatar>
                          </template>
                          
                          <v-list-item-title class="text-body-1 font-weight-medium">
                            {{ project.name }}
                          </v-list-item-title>
                          
                          <v-list-item-subtitle class="text-grey-darken-1">
                            {{ project.description }}
                          </v-list-item-subtitle>
                        </v-list-item>
                        
                        <v-divider v-if="project.id !== projects[projects.length - 1].id" inset></v-divider>
                      </template>
                    </v-list>
                  </v-card>
                </v-container>
              </div>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script setup>
import { useRoute } from "vue-router";
import { ref, computed, onMounted, onUnmounted } from "vue";
import CustomBreadcrumb from "@/components/CustomBreadcrumb.vue";

const isWideScreen = ref(false);

const getColCount1 = computed(() => {
  // 700px以上であれば12カラム、それ以下であれば6カラム
  return isWideScreen.value ? 9 : 12;
});

const checkScreenWidth = () => {
  isWideScreen.value = window.innerWidth >= 700;
};

onUnmounted(() => {
  window.removeEventListener("resize", checkScreenWidth);
});

onMounted(async () => {
  // 初回チェックとリスナー追加
  checkScreenWidth();
  window.addEventListener("resize", checkScreenWidth);
});

const breadcrumbs = computed(() => {
  const route = useRoute();
  return route.meta.breadcrumbs || [];
});

// プロジェクトのリスト
const projects = ref([
  {
    id: 1,
    name: "EventLink",
    description: "イベント計画管理ツール",
    image: "../assets/neko.png", // プロジェクトの画像
    link: "/product/1", // 詳細ページへのリンク
  },
  {
    id: 2,
    name: "個人開発管理ツール（仮）",
    description: "sakamona氏と制作予定",
    image: "../assets/neko.png", // プロジェクトの画像
    link: "/product/2", // 詳細ページへのリンク
  },
  // 他のプロジェクト
]);
</script>

<style scoped>
.projects-list {
  padding: 20px;
}

.v-card {
  margin: 10px 0;
}
</style>
