<template>
  <v-app id="inspire">
    <v-main class="bg-grey-lighten-3">
      <!-- パンくずリスト -->
      <CustomBreadcrumb :breadcrumbs="breadcrumbs" />
      <!-- メインコンテンツ -->
      <v-container fluid>
        <v-row class="center-align">
          <!-- 左側のサイドバー -->
          <v-col cols="12" md="3">
            <div class="my-5" />
            <div class="banner">
              <v-text-field
                type="text"
                :loading="loading"
                prepend-inner-icon="mdi-magnify"
                density="compact"
                label="Search"
                hide-details
                single-line
                clearable
                v-model="searchQuery"
                @input="logSearchQuery"
              ></v-text-field>
            </div>
            <div class="my-2" />
            <span class="mr-2">
              <v-btn @click="performQuerySearch">
                <span class="material-icons">search</span></v-btn
              >
            </span>
            <v-btn @click="resetSearch"><span class="material-icons">close</span></v-btn>

            <span class="mr-4"></span>
            <v-chip-group v-if="searchChip.length">
              <v-chip
                :color="generateRandomColor"
                variant="flat"
                v-for="(chip, index) in searchChip"
                :key="index"
                closable
                @click:close="closeChip(chip)"
              >
                {{ chip }}
              </v-chip>
            </v-chip-group>
          </v-col>

          <!-- メインコンテンツ -->
          <v-col cols="12" md="8">
            <section
              class="main-section container-vertical-layout"
              v-if="paginatedPosts.length"
            >
              <article
                class="post"
                v-for="post in paginatedPosts"
                :key="post.id"
              >
                <v-card>
                  <figure>
                    <a
                      :href="fetchPath(post)"
                      style="background: rgb(255, 255, 255)"
                    >
                      <v-img
                        :src="post.image_url || defaultImage"
                        height="200"
                        width="400"
                      ></v-img>
                    </a></figure
                ></v-card>

                <a
                  :href="fetchPath(post)"
                  style="background: rgb(255, 255, 255)"
                >
                  <h2 class="post-title">{{ post.title }}</h2></a
                >

                <v-chip-group v-if="post.category" class="mb-2">
                  <v-chip
                    v-for="(category, index) in post.category.split(' ')"
                    :color="generateRandomColor"
                    :key="index"
                    :class="'post-category'"
                    @click="addChip(category)"
                  >
                    {{ category }}
                  </v-chip>
                </v-chip-group>
                <v-divider></v-divider>
              </article>
            </section>

            <div v-else-if="!isLoading">
              <v-progress-circular
                color="#f3948f"
                indeterminate
              ></v-progress-circular>
            </div>
            <div v-else>
              <p>該当する投稿がありません。</p>
            </div>

            <v-pagination
              v-model="currentPage"
              :length="totalPages"
              @update:modelValue="updatePagination"
              class="d-flex justify-center my-2"
              size="small"
              color="primary"
              rounded="0"
            ></v-pagination>
          </v-col>

          <!-- 右側のサイドバー -->
          <v-col cols="12" md="3"> </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { useRoute } from "vue-router";
import { getAllPosts, getPostsNoArchive } from "@/firebase/firebase_function";
import CustomBreadcrumb from "@/components/CustomBreadcrumb.vue";
import defaultImage from "@/assets/default.png";

export default {
  props: ["admin"],
  components: {
    CustomBreadcrumb,
  },
  setup(props) {
    const admin = ref(props.admin);
    const posts = ref([]);
    const isLoading = ref(false);
    const searchQuery = ref("");
    const searchChip = ref([]);
    const filteredPosts = ref([]);
    const currentPage = ref(1);
    const itemsPerPage = ref(4);

    const generateRandomColor = ref("");
    const colors = [
      "red",
      "blue",
      "green",
      "yellow",
      "purple",
      "orange",
      "pink",
    ];

    // 記事をページごとに分割
    const paginatedPosts = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage.value;
      const end = start + itemsPerPage.value;
      return filteredPosts.value.slice(start, end);
    });

    // ページ数を計算
    const totalPages = computed(() => {
      return Math.ceil(filteredPosts.value.length / itemsPerPage.value);
    });

    const performQuerySearch = () => {
      if (!searchQuery.value) {
        filteredPosts.value = posts.value;
      } else {
        filteredPosts.value = posts.value.filter(
          (post) =>
            post.title.includes(searchQuery.value) ||
            post.category.includes(searchQuery.value)
        );
      }
      currentPage.value = 1;
    };

    const performChipSearch = () => {
      if (!searchChip.value.length) {
        filteredPosts.value = posts.value;
      } else {
        filteredPosts.value = posts.value.filter((post) =>
          searchChip.value.some((chip) => post.category.includes(chip))
        );
      }
      currentPage.value = 1;
    };

    const logSearchQuery = (event) => {
      searchQuery.value = event.target.value;
    };

    const fetchPath = (post) => {
      return admin.value ? `/admin/post/${post.id}` : `/post/${post.id}`;
    };

    const resetSearch = () => {
      searchQuery.value = "";
      searchChip.value = [];
      filteredPosts.value = posts.value;
      currentPage.value = 1;
    };

    const addChip = (chip) => {
      if (!searchChip.value.includes(chip)) {
        searchChip.value.push(chip);
      }
      performChipSearch();
    };

    const closeChip = (chip) => {
      searchChip.value = searchChip.value.filter((item) => item !== chip);
      performChipSearch();
    };

    const updatePagination = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    onMounted(async () => {
      isLoading.value = true;
      posts.value = admin.value
        ? await getAllPosts()
        : await getPostsNoArchive();
      isLoading.value = false;
      filteredPosts.value = posts.value;
      generateRandomColor.value = colors[1];
    });

    return {
      posts,
      searchChip,
      closeChip,
      fetchPath,
      searchQuery,
      filteredPosts,
      paginatedPosts,
      logSearchQuery,
      performQuerySearch,
      performChipSearch,
      resetSearch,
      addChip,
      generateRandomColor,
      defaultImage,
      currentPage,
      itemsPerPage,
      totalPages,
      updatePagination,
    };
  },
  computed: {
    breadcrumbs() {
      const route = useRoute();
      return route.meta.breadcrumbs || [];
    },
  },
};
</script>
