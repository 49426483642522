<template>
  <v-app id="inspire">
    <v-main class="bg-grey-lighten-3">
      <v-container>
        <v-row justify="center">
          <v-col class="col-12 col-md-6" :cols="getColCount2">
            <!-- カード形式に変更 -->
            <v-card>
              <v-card-title class="headline">Post / Notice</v-card-title>
              <v-card-subtitle>フォームに入力してください</v-card-subtitle>
              <v-card-text>
                <v-radio-group v-model="postType" row>
                  <v-radio label="Post" value="post" />
                  <v-radio label="Notice" value="notice" />
                </v-radio-group>

                <v-divider class="my-4" />

                <!-- Archive セクション -->
                <v-row align="center" justify="space-between">
                  <v-col cols="auto">
                    <p>Archive</p>
                  </v-col>
                  <v-col cols="auto">
                    <ToggleSwitchWidget v-model="archive_flag" />
                  </v-col>
                </v-row>

                <!-- Title Input -->
                <v-text-field
                  label="Title"
                  prepend-icon="$vuetify"
                  v-model="title"
                  variant="solo-filled"
                  clearable
                  class="mt-4"
                />

                <!-- Category Input -->
                <v-text-field
                  label="Category (Half-width space delimiter)"
                  prepend-icon="$vuetify"
                  v-model="category"
                  variant="solo-filled"
                  clearable
                  class="mt-4"
                />

                <!-- Image Upload -->
                <v-file-input
                  label="Image Upload"
                  prepend-icon="mdi-image"
                  v-model="selectedImage"
                  accept="image/*"
                  clearable
                  class="mb-4"
                />

                <!-- Content Editor -->
                <MdEditor
                  v-model="content"
                  language="jp_JP"
                  preview-theme="github"
                />
              </v-card-text>

              <v-card-actions>
                <!-- 登録ボタン -->
                <v-btn
                  v-if="noticeId === 'n' && postType == 'notice'"
                  @click="register"
                  class="mr-4"
                  color="primary"
                  >Notice投稿</v-btn
                >
                <!-- 編集ボタン -->
                <v-btn
                  v-if="noticeId !== 'n' && postType == 'notice'"
                  @click="register"
                  class="mr-4"
                  color="primary"
                  >編集</v-btn
                >
                <!-- 削除ボタン -->
                <v-btn
                  v-if="noticeId !== 'n' && postType == 'notice'"
                  @click="deleter"
                  color="red"
                  >削除</v-btn
                >

                <!-- 登録ボタン -->
                <v-btn
                  v-if="postId === 'p' && postType == 'post'"
                  @click="register"
                  class="mr-4"
                  color="primary"
                  >Post投稿</v-btn
                >
                <!-- 編集ボタン -->
                <v-btn
                  v-if="postId !== 'p' && postType == 'post'"
                  @click="register"
                  class="mr-4"
                  color="primary"
                  >編集</v-btn
                >
                <!-- 削除ボタン -->
                <v-btn
                  v-if="postId !== 'p' && postType == 'post'"
                  @click="deleter"
                  color="red"
                  >削除</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>


<script setup>
import { MdEditor } from "md-editor-v3";
import "md-editor-v3/lib/style.css";
import { ref, onMounted, computed } from "vue";
import {
  getFirestore,
  collection,
  doc,
  setDoc,
  deleteDoc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import {
  getStorage,
  ref as storageRef,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { getPostById, getNoticeById } from "@/firebase/firebase_function";
import app from "@/firebase/firebase";
import { useRoute } from "vue-router";
import router from "@/router";
import ToggleSwitchWidget from "./ToggleSwitchWidget.vue";

const db = getFirestore(app);
const storage = getStorage(app);

const title = ref("");
const category = ref("");
const content = ref("");
const image_url = ref("");
const archive_flag = ref(false);
const postType = ref("");
const selectedImage = ref(null);
const noticeId = ref("n");
const postId = ref("p");
const route = useRoute();

const isWideScreen = ref(false);
const getColCount2 = computed(() => {
  // 700px以上であれば12カラム、それ以下であれば6カラム
  return isWideScreen.value ? 0 : 0;
});

onMounted(async () => {
  const fullPath = route.fullPath;
  const parts = fullPath.split("/");
  postType.value = parts[2];

  if (postType.value === "p") {
    postId.value = parts[3] || "p";
    if (postId.value !== "p") {
      const post = await getPostById(postId.value);
      title.value = post.title;
      category.value = post.category;
      content.value = post.content;
      image_url.value = post.imageUrl;
      archive_flag.value = post.archive_flag;
    }
  } else if (postType.value === "n") {
    noticeId.value = parts[3] || "n";
    if (noticeId.value !== "n") {
      const notice = await getNoticeById(noticeId.value);
      title.value = notice.title;
      content.value = notice.content;
      // image_url.value = post.imageUrl;
      archive_flag.value = notice.archive_flag;
    }
  }
});

const register = async () => {
  if (!title.value.trim() || !content.value.trim()) {
    alert("必須フィールドが空白です。");
    return;
  }

  const saveData = async () => {

    const collectionName = postType.value === "post" ? "posts" : "notices";
    const id = postType.value === "post" ? postId.value : noticeId.value;

    const docRef =
      id === "p" || id === "n"
        ? doc(collection(db, collectionName))
        : doc(collection(db, collectionName), id);

    try {
      // Firestoreにデータを登録
      if (id === "p" || id === "n") {
        await setDoc(docRef, {
          title: title.value,
          category: category.value,
          content: content.value,
          archive_flag: archive_flag.value,
          created_at: serverTimestamp(),
        });
      } else {
        await updateDoc(docRef, {
          title: title.value,
          category: category.value,
          content: content.value,
          archive_flag: archive_flag.value,
          update_at: serverTimestamp(),
        });
      }
      alert(
        `${
          postType.value === "post" ? "記事" : "お知らせ"
        }を投稿しました。ID: ${docRef.id}`
      );

      // 画像アップロード
      if (selectedImage.value) {
        // Storage参照を作成
        const imageRef = storageRef(storage, `posts/${docRef.id}`); // IDを使ってStorageにアップロード
        const uploadTask = uploadBytesResumable(imageRef, selectedImage.value);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`画像アップロード中: ${progress.toFixed(2)}%`);
          },
          () => alert("画像アップロードに失敗しました。"),
          async () => {
            try {
              // 画像アップロード完了後、URLを取得
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref); // 正しい参照を使用
              // Firestoreのデータを更新（画像URLをセット）
              await updateDoc(docRef, { image_url: downloadURL });
              alert("画像をアップロードし、URLをFirestoreに更新しました。");

              // 完了後、適切な画面に遷移
              router.push(postType.value === "post" ? "/" : "/notice");
            } catch (error) {
              alert("画像URLの取得に失敗しました: " + error.message);
            }
          }
        );
      } else {
        // 画像がない場合はそのまま進む
        router.push(postType.value === "post" ? "/" : "/notice");
      }
    } catch (error) {
      alert(`データ送信時にエラーが発生しました: ${error.message}`);
    }
  };

  saveData(); // 初期呼び出し
};

const deleter = async () => {
  const id = postType.value === "post" ? postId.value : noticeId.value;
  const collectionName = postType.value === "post" ? "posts" : "notices";

  // Firestoreのドキュメント削除
  const docRef = doc(collection(db, collectionName), id);

  try {
    // Firestoreのデータ削除
    await deleteDoc(docRef);
    alert(
      `${
        postType.value === "post" ? "記事" : "お知らせ"
      }を削除しました。ID: ${id}`
    );

    // 画像が存在する場合、Firebase Storageから削除
    const imageRef = storageRef(storage, `posts/${id}`); // Firebase Storageでファイルの参照を取得
    await deleteObject(imageRef);
    alert(`Firebase Storageから画像を削除しました。`);

    // 遷移
    router.push(postType.value === "post" ? "/" : "/notice");
  } catch (error) {
    alert(`データ削除時にエラーが発生しました: ${error.message}`);
  }
};
</script>

<style scoped>
/* 必要なスタイル設定 */
</style>
