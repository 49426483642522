<template>
  <v-app id="inspire">
    <v-main class="bg-grey-lighten-4">
      <!-- パンくずリスト -->
      <CustomBreadcrumb :breadcrumbs="breadcrumbs" />
      <v-container fluid>
        <v-row justify="center">
          <v-col class="col-1" :cols="getColCount1">
            <v-sheet
              min-height="70vh"
              rounded="xl"
              class="d-flex flex-column justify-center pa-4 elevation-3 bg-white"
            >
              <div class="projects-list">
                  <CommentComponent />
              </div>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script setup>
import { useRoute } from "vue-router";
import { ref, computed, onMounted, onUnmounted } from "vue";
import CustomBreadcrumb from "@/components/CustomBreadcrumb.vue";
import CommentComponent from "@/components/CommentComponent.vue";

const isWideScreen = ref(false);

const getColCount1 = computed(() => {
  // 700px以上であれば12カラム、それ以下であれば6カラム
  return isWideScreen.value ? 9 : 12;
});

const checkScreenWidth = () => {
  isWideScreen.value = window.innerWidth >= 700;
};

onUnmounted(() => {
  window.removeEventListener("resize", checkScreenWidth);
});

onMounted(async () => {
  // 初回チェックとリスナー追加
  checkScreenWidth();
  window.addEventListener("resize", checkScreenWidth);
});

const breadcrumbs = computed(() => {
  const route = useRoute();
  return route.meta.breadcrumbs || [];
});
</script>

<style scoped>
.projects-list {
  padding: 5px;
}

.v-card {
  margin: 10px 0;
}
</style>
